import styled from 'styled-components';

const Section = styled.main`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 18px;

  @media(min-width: 600px) {
    flex-direction: row;
  }
`

const Name = styled.h1`
  font-family: 'Roboto', sans-serif !important;
  color: #FFFFFF;
  text-transform: capitalize;
  font-style: normal;
  font-weight: bold;
  font-size: 28px;
  line-height: 33px;
  text-align: left;
  margin-bottom: 0;

  @media(min-width: 600px) {
    font-size: 36px;
    line-height: 42px;
    padding-right: 24px;
  }
`

const Logo = styled.img`
  margin-right: 12px;
  margin-bottom: 0px;
  max-width: 20px;
  min-width: 20px;
  max-height: 20px;
  min-height: 20px;
  border-radius: 100%;

  @media(min-width: 600px) {
    max-width: 56px;
    min-width: 56px;
    max-height: 56px;
    min-height: 56px;
  }
`
const Container = styled.div`
  width: calc(100% - 12px);
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 18px;
  padding: ${props => props.secondary ? '12px 24px' : '0px'};
  border-radius: ${props => props.secondary ? '8px' : '0px'};
  border: ${props => props.secondary ? '1px solid #4300D2' : 'none'};
  
  @media(min-width: 600px) {
    padding: ${props => props.secondary ? '24px' : '0px'};
  }
`
const Label = styled.p`
  font-family: 'Roboto', sans-serif !important;
  font-style: normal;
  letter-spacing: 0.7px;
  font-weight: ${props => props.secondary ? 'normal' : 'bold'};
  font-size: ${props => props.secondary ? '14px' : '12px'};
  line-height: ${props => props.secondary ? '16px' : '14px'} ;
  color: ${props => props.secondary ? '#FFFFFF' : '#4300D2'};
  margin: ${props => props.first ? '0 auto 0 0' : '0'};
  padding-right: ${props => props.price ? '24px' : '0px'};
  padding-left: ${props => props.cap ? '24px' : '0px'};

  @media(min-width: 600px) {
    padding-right: ${props => (props.secondary && !props.first) ? '0' : '36px'};
    font-size: ${props => props.secondary ? '18px' : '16px'};
    min-width: ${props => props.cap ? '220px' : 'auto'};;
  }

`

export { Name, Container, Section, Logo, Label };