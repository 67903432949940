import React, { useEffect, useState } from 'react';
import {
  Container,
  Header,
  Row,
  Logo,
  Field,
  Anchor,
  Icon
} from './Components';
import { v4 as uuidv4 } from 'uuid';
import axios from 'axios';
const apiUrl = process.env.GATSBY_API_URL;
const apiKey = process.env.GATSBY_API_KEY;

const IndexHoldings = ({
  holdings = [],
  indexId = ''
}) => {

  const [latestHoldings, setLatestHoldings] = useState(holdings)

  useEffect(() => {
    const getLatestHoldings = async () => {
      try {
        const { data: updatedCoinHoldings = [] } = await axios.get(`${apiUrl}/v1/indices/${indexId}/holdings`, { headers: { 'X-Api-Key': apiKey } })
        const { holdings } = updatedCoinHoldings;
        setLatestHoldings(holdings)
      } catch (e) {
        //
      }
    }
    getLatestHoldings();
  }, [indexId])


  return (
    <Container>
      <Header>
        <span> Holdings </span>
        <span> Weight </span>
      </Header>

      {latestHoldings.sort((a, b) => b.weight - a.weight).map((holding, i) => {

        const {
          logo: coinLogo,
          coin: { ticker, name: coinName, website },
          weight
        } = holding || {};

        return (
          <Row
            first={i === 0}
            last={i === holdings.length - 1}
            key={uuidv4()}
          >
            <Logo
              src={coinLogo && coinLogo.url}
              alt={coinName}
            />
            <Field>
              {ticker}
            </Field>
            <Anchor href={website} target={'__blank'} rel="noreferrer noopener">
              <Icon />
            </Anchor>
            <Field last>
              {weight.toFixed(2)}%
            </Field>
          </Row>
        )
      })}
    </Container>
  )
}

export default IndexHoldings;