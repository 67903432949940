import React from 'react';
import { StyledLink } from './Components';

const Breadcrumbs = () => {
  return (
    <StyledLink to={'/'}>
      {'< Homepage'}
    </StyledLink>
  )
};

export default Breadcrumbs;