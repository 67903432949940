import styled from 'styled-components';


const Section = styled.section`
  margin-top: 24px;
  width: 100%;
  padding: 0 8px;
  margin-bottom: 32px;

  @media(min-width: 600px) {
    display: flex;
    margin-top: 36px;
    margin-bottom: 55px;
  }
`

const Description = styled.div`
  width: 100%;
  @media(min-width: 600px) {
    padding-right: 39px;
  }
`

const Title = styled.h2`
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  color: #FFFFFF;
  margin-bottom: 12px;

  @media(min-width: 769px) {
    font-size: 18px;
  }
`

const Body = styled.div`
  font-family: 'Roboto', sans-serif !important;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 132.4%;
  color: #FFFFFF;

  @media(min-width: 769px) {
    font-size: 16px;
    line-height: 21px;
  }

`

export { Section, Description, Title, Body }