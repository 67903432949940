import React from 'react';
import Layout from "../components/layout";
import SEO from "../components/seo";
import { StaticQuery, graphql } from "gatsby";
import PricesChart from '../components/PricesChart/PricesChart.jsx';
import Breadcrumbs from '../components/Breadcrumbs/Breadcrumbs.jsx';
import IndexPageHeader from '../components/IndexPageHeader/IndexPageHeader.jsx';
import IndexInfoSection from '../components/IndexInfoSection/IndexInfoSection.jsx';

const IndexPage = ({ pageContext }) => {

  const {
    name: indexName,
    _id: indexId,
    price: indexPrice,
    market_cap: indexMarketCap,
    logo: indexLogo,
    description: indexDescription,
    holdings,
  } = pageContext || {};

  return (
    <StaticQuery
      query={graphql`
        query PriceQuery {
          allPrices {
            nodes {
              _id
              prices {
                price
                last_updated
              }
              daily_prices {
                price
                last_updated
              }
            }
          }
        }`
      }
      render={(data) => {

        const indexPricesData = data.allPrices.nodes.find(indexPrices => indexPrices._id === indexId) || {};

        const { prices = [], daily_prices = [] } = indexPricesData;

        return (
          <Layout>
            <SEO title={`${indexName}`} />
            <Breadcrumbs />
            <IndexPageHeader
              indexName={indexName}
              indexLogo={indexLogo}
              indexPrice={indexPrice}
              indexMarketCap={indexMarketCap}
              indexId={indexId}
            />
            <PricesChart
              prices={prices}
              dailyPrices={daily_prices}
              indexName={indexName}
              indexId={indexId}
            />
            <IndexInfoSection
              indexDescription={indexDescription}
              holdings={holdings}
              indexId={indexId}
            />
          </Layout>
        )
      }}
    />
  )
}

export default IndexPage;