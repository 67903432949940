import styled from 'styled-components';
import { OpenInNew as OpenInNewIcon } from '@material-ui/icons';


const Container = styled.div`
 width: 100%; 
`

const Header = styled.header`
  color: #4300D2;
  padding: 12px 22px;
  display: flex;
  font-size: 14px;
  line-height: 18px;
  justify-content: space-between;

  @media(min-width: 600px) {
    font-size: 18px;
    line-height: 21px;
    padding: 0 22px 22px 22px;
  }
`

const Row = styled.div`
  padding: 22px;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 16px;
  color: #FFFFFF;
  border: 1px solid #4300D2;
  box-sizing: border-box;
  border-top-left-radius: ${props => props.first ? '7px' : 0};
  border-top-right-radius: ${props => props.first ? '7px' : 0};
  border-bottom-left-radius: ${props => props.last ? '7px' : 0};
  border-bottom-right-radius: ${props => props.last ? '7px' : 0};
  display: flex;
  align-items: center;
`;

const Logo = styled.img`
  margin-right: 12px;
  margin-bottom: 0px;
  max-width: 22px;
  min-width: 22px;
  max-height: 22px;
  min-height: 22px;

  @media(min-width: 600px) {
    max-width: 32px;
    min-width: 32px;
    max-height: 32px;
    min-height: 32px;
  }
`

const Field = styled.span`
  margin: ${props => props.last ? '0 0 0 auto' : '0'};

  @media(min-width: 600px) {
    font-size: 18px;
    line-height: 21px;
  }
`

const Icon = styled(OpenInNewIcon)`
  width: 0.4em !important;
  height: 0.4em !important;
`

const Anchor = styled.a`
  padding-bottom: 6px;
  padding-left: 4px;  
`

export { Container, Header, Row, Logo, Field, Anchor, Icon }