import styled from 'styled-components';
import { Link } from 'gatsby';

const StyledLink = styled(Link)`
  font-family: 'Roboto', sans-serif !important;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  margin: 22px 0;
  color: #4300D2;
  display: block;

  @media(min-width: 769px) {
    margin: 42px 0;
  }

`;

export { StyledLink }