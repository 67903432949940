
import React, { useState, useEffect } from 'react';
import {
  LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip, ResponsiveContainer
} from 'recharts';
import moment from 'moment-timezone';
import axios from 'axios';
import { formatPrice } from '../../utils/';
import ReactResizeDetector from 'react-resize-detector';
import { Section } from './Component';
import { Tab, Tabs } from '@material-ui/core';
import { v4 as uuidV4 } from 'uuid';
import styles from './PricesChart.module.css';
import _ from 'lodash';


const apiUrl = process.env.GATSBY_API_URL;
const apiKey = process.env.GATSBY_API_KEY;

const PricesChart = ({ prices = [], dailyPrices = [], indexName = '', indexId = '' }) => {

  const [allPrices, setAllPrices] = useState(prices);
  const [allDailyPrices, setAllDailyPrices] = useState(dailyPrices)
  const [tab, setTab] = useState(0);

  useEffect(() => {
    const updatePriceChart = async (allPrices) => {

      const latestDataReading = allPrices[0];

      if (latestDataReading) {

        const { last_updated } = latestDataReading;
        const utcNow = moment.utc();
        const lastUpdatedDate = moment.tz(last_updated, 'UTC')
        const duration = moment.duration(utcNow.diff(lastUpdatedDate));
        const minutes = duration.asMinutes();
        const numberOfNewReadings = Math.floor(minutes / 10);

        if (numberOfNewReadings > 0) {
          const { data: latestPriceReadings = [] } = await axios.get(`${apiUrl}/v1/indices/${indexId}/prices?limit=${numberOfNewReadings}`, { headers: { 'X-Api-Key': apiKey } })

          const updatedPrices = [...latestPriceReadings, ...allPrices];

          setAllPrices(updatedPrices)

          const clonedDailyPrices = _.clone(allDailyPrices);

          clonedDailyPrices[0] = latestPriceReadings[0];

          setAllDailyPrices(clonedDailyPrices)
        }

      }
    }


    updatePriceChart(allPrices);
    const ID = setInterval(() => updatePriceChart(allPrices), 1000 * 60 * 10);

    return () => {
      clearInterval(ID)
    }

  }, [indexId, allPrices, allDailyPrices])

  const onChangePrices = (event, newValue) => {
    setTab(newValue)
  }

  function formatXAxis(tickItem) {
    if (tab === 0) {
      return moment(tickItem).format('MMMM-DD');
    } else {
      return moment(tickItem).subtract('10', 'minutes').format('MMMM-DD');
    }
  }

  function formatYAxis(tickItem) {
    return formatPrice(tickItem)
  }

  return (
    <ReactResizeDetector
      handleWidth
      refreshMode={'debounce'}
      refreshRate={300}
    >
      {({ width }) => {
        return (
          <>
            <Tabs
              value={tab}
              onChange={onChangePrices}
              indicatorColor="primary"
              aria-label="graph toggle prices"
              classes={{ root: styles.toggle }}
            >
              <Tab
                label="1W"
                id={uuidV4()}
                aria-controls={uuidV4()}
                classes={{ root: `${styles.firstTab} ${styles.tab}` }}
              />
              <Tab
                label="ALL"
                id={uuidV4()}
                aria-controls={uuidV4()}
                classes={{ root: styles.tab }}
              />
            </Tabs>
            <Section>
              <ResponsiveContainer width={'100%'} height={300} >
                <LineChart
                  data={tab === 0 ? allPrices : allDailyPrices}
                  margin={{ top: 0, right: 0, left: 0, bottom: 0 }}
                >
                  <CartesianGrid
                    strokeDasharray="12 5"
                    vertical={false}
                    stroke="rgba(89,106,131,0.25)"
                  />
                  <Line
                    type="monotone"
                    dataKey="price"
                    fill="#007bff"
                    dot={false}
                    name={`${indexName} price`}
                    isAnimationActive={false}
                  />
                  <XAxis
                    dataKey="last_updated"
                    reversed
                    tickFormatter={formatXAxis}
                    tick={{ fontSize: (width > 375) ? 12 : 8, fill: '#FFFFFF' }}
                    padding={{ right: (width > 375) ? 25 : 15 }}
                    interval={tab === 0 ? (24 * 60 / 10) : "preserveStartEnd"}
                    domain={['auto', 'auto']}
                  />
                  <YAxis
                    type="number"
                    domain={['auto', 'auto']}
                    tickFormatter={formatYAxis}
                    tick={{ fontSize: (width > 375) ? 12 : 8, fill: '#FFFFFF' }}
                    tickSize={(width > 375) ? 12 : 8}
                    mirror={(width < 375)}
                  />
                  <Tooltip />
                </LineChart>
              </ResponsiveContainer>
            </Section>
          </>
        )
      }}
    </ReactResizeDetector>
  )
};

export default PricesChart;