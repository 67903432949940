import React, { useState, useEffect } from 'react';
import {
  Section, Container, Name, Logo, Label
} from './Components';
import { formatPrice, getLatestIndexData } from '../../utils';

const IndexPageHeader = ({
  indexName = '',
  indexPrice,
  indexMarketCap,
  indexLogo = { url: '' },
  indexId
}) => {

  const { url } = indexLogo;

  const [marketCap, setMarketCap] = useState(formatPrice(indexMarketCap / 1e6));

  const [price, setPrice] = useState(formatPrice(indexPrice));

  useEffect(() => {
    const updatePrice = async (indexId) => {
      if (indexId) {
        try {
          const { price, market_cap } = await getLatestIndexData(indexId)
          setPrice(formatPrice(price))
          setMarketCap(formatPrice(market_cap / 1e6))
        } catch (e) {
          //
        }
      }
    }
    updatePrice(indexId)

    const ID = setInterval(() => { updatePrice(indexId) }, 1000 * 60 * 10)

    return () => {
      clearInterval(ID)
    }
  }, [indexId])

  return (
    <Section>
      <Container>
        <Logo src={url} alt={indexName} />
        <Name>
          {indexName.toLocaleLowerCase()}
        </Name>
      </Container>
      <div>
        <Container>
          <Label first cap> Market Cap (M) </Label>
          <Label price> Price </Label>
        </Container>
        <Container secondary>
          <Label secondary first> {marketCap} </Label>
          <Label secondary> {price} </Label>
        </Container>
      </div>
    </Section>
  )
};

export default IndexPageHeader;