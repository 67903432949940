import styled from 'styled-components';

const Section = styled.section`
  width: 100%;
  min-height: 180px;
  padding: 16px 8px 8px 8px;
  border: 1px solid #4300D2;
  box-sizing: border-box;
  border-radius: 7px;
`

export { Section };