import React from 'react';
import showdown from 'showdown';
import { Section, Description, Title, Body } from './Components';
import IndexHoldings from '../IndexHoldings/IndexHoldings.jsx';

const converter = new showdown.Converter();

const IndexInfoSection = ({ holdings = [], indexId = '', indexDescription = '' }) => {

  const html = converter.makeHtml(indexDescription);

  return (
    <Section>
      <Description>
        <Title>
          Description
        </Title>
        <Body
          dangerouslySetInnerHTML={{ __html: html }}
        />
      </Description>
      <IndexHoldings
        holdings={holdings}
        indexId={indexId}
      />
    </Section>
  )

}

export default IndexInfoSection;